import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import VueGoodTablePlugin from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import { plugin, defaultConfig } from '@formkit/vue'
import '@formkit/themes/genesis'
import VueFeather from 'vue-feather';

const app = createApp(App)
app.component('VueFeather', VueFeather)
app.use(store)
    .use(VueGoodTablePlugin)
    .use(router)
    .use(plugin, defaultConfig)
    .mount('#app')