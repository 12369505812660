import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: 'dashboard',
        component: () => import('@/layouts/DashboardLayout.vue'),
        meta: { requiresAuth: true, },
        children: [{
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('@/views/dashBoard.vue'),
        },
        {
            path: '/my-profile',
            name: 'my-profile',
            component: () => import('@/views/member/MemberProfile.vue'),
        },
        {
            path: '/edit-profile',
            name: 'edit-profile',
            component: () => import('@/views/member/EditProfile.vue'),
        },
        {
            path: '/my-family',
            name: 'my-family',
            component: () => import('@/views/member/MemberFamily.vue'),
        },
        {
            path: '/my-payments',
            name: 'my-payments',
            component: () => import('@/views/accounts/MemberPayments.vue'),
        },
        {
            path: '/masavari',
            name: 'masavari',
            component: () => import('@/views/accounts/familyMasavari.vue'),
        },
        {
            path: '/transactions',
            name: 'new-transactions',
            component: () => import('@/views/accounts/newTransactions.vue'),
        },
        {
            path: '/all-transactions',
            name: 'list-transactions',
            component: () => import('@/views/accounts/listTransactions.vue'),
        },
        {
            path: '/change-password',
            name: 'change-password',
            component: () => import('@/views/member/ChangePassword.vue'),
        },
        {
            path: '/add/member',
            name: 'new-member',
            component: () => import('@/views/member/addMember.vue'),
        },
        {
            path: '/view/member/:memberid',
            name: 'view-member',
            component: () => import('@/views/member/viewMember.vue'),
        },
        {
            path: '/member/edit/:memberid',
            name: 'edit-member',
            component: () => import('@/views/member/editMember.vue'),
        },
        {
            path: '/add/birth',
            name: 'new-birth',
            component: () => import('@/views/birth/addBirth.vue'),
        },
        {
            path: '/edit/birth/:member_id',
            name: 'edit-birth',
            component: () => import('@/views/birth/editBirth.vue'),
        },
        {
            path: '/add/death',
            name: 'new-death',
            component: () => import('@/views/death/addDeath.vue'),
        },
        {
            path: '/edit/death/:member_id',
            name: 'edit-death',
            component: () => import('@/views/death/editDeath.vue'),
        },
        {
            path: '/add/marriage',
            name: 'new-marriage',
            component: () => import('@/views/marriage/addMarriage.vue')

        },
        {
            path: '/view/marriage/:marrappid',
            name: 'view-marriage',
            component: () => import('@/views/marriage/viewMarriage.vue'),
        },
        {
            path: '/edit/marriage/:editmarrappid',
            name: 'edit-marriage',
            component: () => import('@/views/marriage/editMarriage.vue'),
        },

        ]
    },


    {
        path: '/auth',
        name: 'Auth',
        component: () =>
            import('@/layouts/AuthenticationLayout.vue'),
        meta: {
            requiresAuth: false,
        },
        children: [{
            path: '/login/',
            name: 'login',
            component: () => import('@/views/auth/LoginPage.vue')
        },
        ]
    },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import("@/views/NotFound.vue"),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
     //check meta
    const authRequired = to.matched.some(record => record.meta.requiresAuth)
    const loggedIn = localStorage.getItem('member');

    if (authRequired && !loggedIn) {
        next('/login');
    } else {
        next()
    }
})

export default router